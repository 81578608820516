<template>
  <div class="events"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row :class="sm-6" >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                      
               
            <!-- Dialog para agregar --> 
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Empleado</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <EmployeeCreate :user="user" />
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Empleado</span>
            </v-tooltip>
        </template> 
         <!-- FIN Dialog para agregar -->                              
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>


<v-row>
  <div v-for="row in employees" :key="row.id" class="col xs4 sm4 md4 lg4">
  <v-container>
    <v-row justify="space-around">
      <v-card width="400">
        <v-img
          height="220px"
          src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
        >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
          >                                  
            <v-spacer></v-spacer>
            
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>                                                                                                                                                                                
                    </template>
                  </v-tooltip>
                </template>
                <v-list>                                                                                                                              
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn v-on:click="editRow = row.id" icon>
                         <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </v-app-bar>

          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                alt="user"
                src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
              >
            </v-avatar>
            <p class="ml-3">
              {{row.fullname}}
            </p>
            <p class="ml-3">
              <v-rating
                v-model="rating"
                color="yellow darken-2"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                hover
                large
              ></v-rating>
            </p>
            
          </v-card-title>
        </v-img>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            # {{row.id}} <br>
            Telefono {{row.phone}} <br>
            Movil {{row.mobile}} <br>
            Email {{row.email}} <br>
            Tipo {{row.tipo}} <br>
          </div>
          <div v-if="editRow === row.id">
            <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
            <input v-on:keyup.13="updateRow(row)" placeholder="Nombre" v-model="row.firstname" />
            <input v-on:keyup.13="updateRow(row)" placeholder="Apellido" v-model="row.lastname" />
            <input v-on:keyup.13="updateRow(row)" placeholder="Email" v-model="row.email" />
            <input v-on:keyup.13="updateRow(row)" placeholder="Teléfono" v-model="row.phone" />
            <input v-on:keyup.13="updateRow(row)" placeholder="Celular" v-model="row.mobile" />
            <v-combobox
              v-model="row.tipo"
              :items="itemsType"
              label="Selecciona un Tipo"
            ></v-combobox>
            <v-combobox
              v-model="row.puesto"
              :items="itemsPuesto"
              label="Selecciona un Puesto"
            ></v-combobox>

            <!-- 
              <ejs-combobox v-on:keyup.13="updateRow(row)" v-model="row.tipo" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px">
              </ejs-combobox>                        
              <ejs-combobox v-on:keyup.13="updateRow(row)" v-model="row.puesto" :dataSource='dataPuesto' :fields='dataFPuesto' placeholder='Seleccione un puesto' popupWidth="250px">
              </ejs-combobox>                        
           -->
            <input v-on:keyup.13="updateRow(row)" placeholder="Notas" v-model="row.notes" />
            <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
          </div>
        </v-card-text>
        <v-app-bar>
          <div v-if="row.ver == '1'">        
            <v-chip
            class="ma-2"
            color="green"
            label
            text-color="white"
            >
              Activo
            </v-chip>
          </div>                 
          <div v-else>
            <v-chip
            class="ma-2"
            color="default"
            label
            text-color="white"
            >
              Desactivado
            </v-chip>
          </div>
          <div v-if="row.notes != null && row.notes != ''">   
            <div class="text-center">
              <v-dialog
                v-model="dialog"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    ver nota
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    Notas
                  </v-card-title>
                  <v-card-text>
                    {{row.notes}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>                                                                       
        </v-app-bar>
      </v-card>
    </v-row>
  </v-container>
  </div>
</v-row>
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
//import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import EmployeeCreate from "@/components/EmployeeCreate";
import router from '@/router';
import baseApi from '@/services/baseApi';




export default {
  name: 'employees',
  created(){
    this.changeBreadcrumbs({
        page:"Empleados",
        title:"Lista de Empleados",
        subtitle:"primary",
        description:"En esta lista encontrará todos los empleados dados de alta en el sistema desde el principio de los tiempos"
    });    
    this.getBreadcrumps(this.sword)    
  },
  computed:{
        ...mapState(['employees'])
  },
  components: {
      EmployeeCreate
      //FieldSearch,
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    }, 
    async getBreadcrumps(sword){    
      this.attribute.sword=sword;
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadEmployees',this.attribute);
      }catch(error){
        if (error.message=='Request failed with status code 500') {
          this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        }else if(error.message=='Request failed with status code 401'){
          this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
          router.push('/login');
        }else{
          this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        }
      }  finally{
        this.hiddenLoading()
      } 
    },
    updateRow(row) {    
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                                             
          baseApi().post(`/empleados/update/${row.id}`,JSON.stringify(row)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
          });              
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        }
        
    }
  },
  data(){
     return{
      dialog2:false,
      attribute: {sword: '',limit: 27},
      sword:"",
      editRow:null,
      itemsType: [
          'Bolsa de Trabajo',
          'Contratado',
      ],          
      itemsPuesto: [
          'TI',
          'Marketing Digital',
          'Electricista',
          'Vendedor',
          'Contador',
          'Mecatrónica',
          'Recursos Humanos',
      ],             
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Sucursal', value: 'branch_id' },
        { text: 'Mailchimp ID', value: 'mailchimp_id' },
        { text: 'Nombre', value: 'firstname' },
        { text: 'Apellido', value: 'lastname' },
        { text: 'Compañía', value: 'company' },
        { text: 'Teléfono', value: 'phone' },
        { text: 'Móvil', value: 'mobile' },        
        { text: 'Email', value: 'email' },        
        { text: 'Fuente', value: 'Fuente' },
        { text: 'Tipo', value: 'status' },
        { text: 'Status', value: 'ver' },
        { text: 'Notas', value: 'notes' },
      ],
    }
  }  
}
</script>
